import React, { useState } from 'react';
import clienteAxios from '../../config/axios';
import Swal from 'sweetalert2';

import useConfigAuth from '../../Hooks/useConfigAuth';

import { Flex, BotonPacienteDos, Table2, Th, Tr, Td1, Thead, Input, Label1, Select} from '../Css/StyledPatient';


const Suscripciones = () => {

    const [config] = useConfigAuth();

    const [disable, setDisable] = useState(false);
    const [suscripciones, setSuscripciones] = useState([]);
    const [editar, setEditar] = useState(false);
    const [id, setId] = useState('');
    const [uid, setUid] = useState('');
    const [plan, setPlan] = useState('');
    const [nombreMedio, setNombreMedio] = useState('');
    const [monto, setMonto] = useState('');
    const [moneda, setMoneda] = useState('');
    const [cantidadSuscriptos, setCantidadSuscriptos] = useState('');

    const solicitarSuscripciones = async (e) => {
        e.preventDefault();
        try {
            setDisable(true)
            const payload = {};
            const informacion = await clienteAxios.post('/usuarios/solicitarsuscripciones', payload, config);
            setSuscripciones(informacion.data);
            setDisable(false);
        } catch (error) {
            setDisable(false);
            console.log(error.response)
        }
    }

    const editarInfo = (e, index) => {
        e.preventDefault();
        const newList = JSON.parse(JSON.stringify(suscripciones));
        setId(newList[index].id);
        setUid(newList[index].id_suscripcion);
        setPlan(newList[index].plan);
        setNombreMedio(newList[index].nombre_medio);
        setMonto(newList[index].monto);
        setMoneda(newList[index].moneda);
        setCantidadSuscriptos(newList[index].cantidad_suscriptos);
        setEditar(true);
    }

    const editarSuscripcion = async (e) => {
        e.preventDefault();
        const payload = {
            id,
            id_suscripcion: uid,
            monto,
            moneda,
            nombre_medio: nombreMedio,
            cantidad_suscriptos: cantidadSuscriptos,
            plan
        }
        const respuesta = await clienteAxios.post(`/usuarios/editarsuscripcion/mercado_pago`, payload, config);
        if(respuesta) {
            Swal.fire({
                icon: 'success',
                title:'La solicitud ha sido un éxito',
                text: `Editado correctamente, corroborar en Mercado Pago`,
            }).then(function() {
                setId('');
                setUid('');
                setPlan('');
                setNombreMedio('');
                setMonto('');
                setMoneda('');
                setCantidadSuscriptos('');
                setEditar(false);
            });
        } else {
            Swal.fire({
                icon: 'error',
                title:'Hubo un error',
                text: `Pedirle ayuda a Santi`,
            }).then(function() {
                setId('');
                setUid('');
                setPlan('');
                setNombreMedio('');
                setMonto('');
                setMoneda('');
                setCantidadSuscriptos('');
                setEditar(false);
            });
        }
    }

    return (
        <Flex direccion="column" margin="2rem 0 0 0">
            {!editar ? 
            <div>
                <Flex>
                    <BotonPacienteDos
                        disabled={disable}
                        onClick={(e) => solicitarSuscripciones(e)}
                    >
                        Traer suscripciones
                    </BotonPacienteDos>
                </Flex>
                <Flex direccion="column" margin="0 0 3rem 0">
                    <Table2>
                        <Thead>
                            <Tr>
                                <Th>UID</Th>
                                <Th>Monto</Th>
                                <Th>Moneda</Th>
                                <Th>Proveedor</Th>
                                <Th>Cant Suscriptos</Th>
                                <Th>N° Plan</Th>
                                <Th>Editar</Th>
                            </Tr>
                        </Thead>
                        <tbody>
                            {suscripciones?.map( (item,i) => 
                            <Tr textalign="center"
                                key={item.id}
                            >
                                <Td1>{item.id_suscripcion}</Td1>
                                <Td1>{item.monto}</Td1>
                                <Td1>{item.moneda}</Td1>
                                <Td1>{item.nombre_medio}</Td1>
                                <Td1>{item.cantidad_suscriptos}</Td1>
                                <Td1>{item.plan}</Td1>
                                <Td1
                                    onClick={(e) => editarInfo(e,i)}
                                >Editar</Td1>
                            </Tr>
                            )}
                        </tbody>
                    </Table2>
                </Flex>
            </div>
            :
            <Flex>
                <form
                    onSubmit={(e) => editarSuscripcion(e)}
                >   
                    <Flex margin="2rem 0 0 0">
                        <Label1>UID: {uid}</Label1>
                    </Flex>
                    <Flex>
                        <Label1>Elegir proveedor</Label1>
                        <Select value={nombreMedio || ""} onChange={e => setNombreMedio(e.target.value)}>
                            <option></option>
                            <option>mercado_pago</option>
                        </Select>
                    </Flex>
                    <Flex>
                        <Label1>N°Plan (Intentar mantener un orden)</Label1>
                        <Input
                            type="numeric"
                            name="plan"
                            placeholder="un numero"
                            value={plan || ""}
                            onChange={e => setPlan(e.target.value)}
                        />
                    </Flex>
                    <Flex>
                        <Label1>Valor del plan</Label1>
                        <Input
                            type="numeric"
                            name="monto"
                            placeholder="ej: 300"
                            value={monto || ""}
                            onChange={e => setMonto(e.target.value)}
                        />
                    </Flex>
                    <Flex>
                        <Label1>Elegir Moneda</Label1>
                        <Select value={moneda || ""} onChange={e => setMoneda(e.target.value)}>
                            <option></option>
                            <option>ARS</option>
                        </Select>
                    </Flex>
                    <Flex>
                        <Label1>¿Cuantos suscriptos?</Label1>
                        <Input
                            type="numeric"
                            name="cantidadSuscriptos"
                            placeholder="ej: 5"
                            value={cantidadSuscriptos || ""}
                            onChange={e => setCantidadSuscriptos(e.target.value)}
                        />
                    </Flex>
                    <BotonPacienteDos
                        type="submit"
                    >
                        Editar
                    </BotonPacienteDos>
                </form>
            </Flex>
            }
        </Flex>
    );
}
 
export default Suscripciones;