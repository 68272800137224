import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import clienteAxios from '../../config/axios';

import useConfigAuth from '../../Hooks/useConfigAuth';

import { setearAlerta } from '../../actions/alertaActions';

import { Flex, Input, Label1, Select, BotonPacienteDos} from '../Css/StyledPatient';

const CargaSuscripcion = () => {

    const dispatch = useDispatch();
    const [config] = useConfigAuth();

    const [disable, setDisable] = useState(false);
    const [plan, setPlan] = useState('');
    const [nombreMedio, setNombreMedio] = useState('');
    const [monto, setMonto] = useState('');
    const [moneda, setMoneda] = useState('');
    const [cantidadSuscriptos, setCantidadSuscriptos] = useState('');

    const crearSuscripcion = async (e) => {
        e.preventDefault();
        setDisable(true);
        try {
            const payload = {
                plan,
                nombreMedio,
                monto,
                moneda,
                cantidadSuscriptos
            }
            const respuesta = await clienteAxios.post(`/usuarios/crearsuscripcion/${nombreMedio}`, payload, config);
            const alerta = {
                msg: respuesta.data.msg
            }
            dispatch( setearAlerta (alerta) );
            setPlan('');
            setNombreMedio('');
            setMonto('');
            setMoneda('');
            setCantidadSuscriptos('');
            setDisable(false);
        } catch (error) {
            console.log(error.response)
            setDisable(false);
        }
    }

    return (
        <Flex>
            <form
                onSubmit={(e) => crearSuscripcion(e)}
            >   
                <Flex margin="2rem 0 0 0">
                    <Label1>Elegir proveedor</Label1>
                    <Select value={nombreMedio || ""} onChange={e => setNombreMedio(e.target.value)}>
                        <option></option>
                        <option>mercado_pago</option>
                    </Select>
                </Flex>
                <Flex>
                    <Label1>N°Plan (Intentar mantener un orden)</Label1>
                    <Input
                        type="numeric"
                        name="plan"
                        placeholder="un numero"
                        value={plan || ""}
                        onChange={e => setPlan(e.target.value)}
                    />
                </Flex>
                <Flex>
                    <Label1>Valor del plan</Label1>
                    <Input
                        type="numeric"
                        name="monto"
                        placeholder="ej: 300"
                        value={monto || ""}
                        onChange={e => setMonto(e.target.value)}
                    />
                </Flex>
                <Flex>
                    <Label1>Elegir Moneda</Label1>
                    <Select value={moneda || ""} onChange={e => setMoneda(e.target.value)}>
                        <option></option>
                        <option>ARS</option>
                    </Select>
                </Flex>
                <Flex>
                    <Label1>¿Cuantos suscriptos?</Label1>
                    <Input
                        type="numeric"
                        name="cantidadSuscriptos"
                        placeholder="ej: 5"
                        value={cantidadSuscriptos || ""}
                        onChange={e => setCantidadSuscriptos(e.target.value)}
                    />
                </Flex>
                <BotonPacienteDos
                    type="submit"
                    disabled={disable}
                >
                    Crear
                </BotonPacienteDos>
            </form>
        </Flex>
    );
}
 
export default CargaSuscripcion;