import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { initMercadoPago, CardPayment } from '@mercadopago/sdk-react';


import useConfigAuth from '../../Hooks/useConfigAuth.js';

import { activarSuscriptorAction, cambiarSuscripcionAction, crearSuscriptorAction, suspenderSuscriptorAction, activarPruebaGratisAction, cancelarPruebaGratisAction } from '../../actions/sucriptorActions.js';
import { estadoErrorInitialAction } from '../../actions/resetStoreActions.js';

import {BotonPacienteDos, P, Flex}  from '../Css/StyledPatient.js';
import { FlexLanding, TextoCorto } from '../Css/StyledLanding';
import { setearAlerta } from '../../actions/alertaActions.js';
import {Error} from '../Css/StyledHeader.js';

initMercadoPago('APP_USR-76532cca-79db-4093-8d7f-e5046c0ab547');

const MiSuscripcionAux = () => {

    const dispatch = useDispatch();
    const [config] = useConfigAuth();

    const colores = [
        {id:1, color:"#4AA4A5"},
        {id:2, color:"#E8BA35"},
        {id:3, color:"#D53A77"},
        {id:4, color:"#F6DEA9"},
        {id:5, color:"#6261D4"},
        {id:6, color:"yellow"}
    ];

    const infoSuscripcion = useSelector((state) => state.usuario.suscripcion);
    const {suscripciones, suscriptor, estado_suscripcion, suscripciones_de_suscriptor} = infoSuscripcion;
    const profesional = useSelector((state) => state.usuario.nombre);
    const rol = useSelector((state) => state.usuario.rol);
    const error = useSelector ( (state) => state.estado.error);

    const [suscripcionElegida, setSuscripcionElegida] = useState('');
    const [montoElegido, setMontoElegido] = useState('');
    const [infoSuscripciones, setInfoSuscripciones] = useState([]);
    const [disable, setDisable] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [mostrarOtrasSuscripciones, setMostrarOtrasSuscripciones] = useState(false);

    const suscripcionActiva = suscripciones_de_suscriptor.filter(item => item.activo === true)[0];

    const pausarSuscripcion = async (e) => {
        e.preventDefault();
        Swal.fire({
            title: "¿Esta seguro de que quiere suspender la suscripción?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, suspender!",
            cancelButtonText:"No"
        }).then(function(result) {
            if(result.isConfirmed) {
                const informacion = {
                    config,
                    payload: {},
                    infoSuscripcion
                }
                dispatch( suspenderSuscriptorAction(informacion) );
            }
        });
    }

    const probarGratis = (e) => {
        e.preventDefault();
        Swal.fire({
            title: "¿Desea activar la versión Demo por 15 días?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, probar!",
            cancelButtonText:"No"
        }).then(function(result) {
            if(result.isConfirmed) {
                const informacion = {
                    config
                }
                dispatch( activarPruebaGratisAction(informacion) );
                setDisable(false);
            }
        });
    }

    const cancelarVersionDemo = (e) => {
        e.preventDefault();
        Swal.fire({
            title: "¿Está seguro que quiere cancelar la versión Demo?",
            icon: "warning",
            text: "Atención: si cancela la versión Demo no podrá volver a solicitarla",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, cancelar!",
            cancelButtonText:"No"
        }).then(function(result) {
            if(result.isConfirmed) {
                const informacion = {
                    config
                }
                dispatch( cancelarPruebaGratisAction(informacion) );
                setDisable(false);
            }
        });
    }

    const initialization = {
        amount: montoElegido,
    };
    const customization = {
        visual: {
            texts: {
                formTitle: "Tarjeta de débito",
                emailSectionTitle: "Completa tu información de Mercado Pago",
                installmentsSectionTitle: "",
                cardholderName: {
                    label: "Nombre del titular como aparece en la tarjeta",
                    placeholder: "María Lopez",
                },
                email: {
                    label: "E-mail de Mercado Pago",
                    placeholder: "ejemplo@email.com",
                },
                cardholderIdentification: {
                    label: "Documento del titular",
                },
                cardNumber: {
                    label: "Número de tarjeta",
                },
                expirationDate: {
                    label: "Vencimiento",
                },
                securityCode: {
                    label: "Código de seguridad",
                },
                selectInstallments: "",
                selectIssuerBank: "",
                formSubmit: "",
            }
        },
        paymentMethods: {
            types: {
                excluded: ['credit_card'],
            }, 
            maxInstallments: 1,
        }
    };

    const onSubmit = async (formData) => {
        formData.id_plan = suscripcionElegida;
        const informacion = {
            config,
            payload: {
                formData,
                id_plan: suscripcionElegida
            },
            infoSuscripcion
        }
        dispatch( cambiarSuscripcionAction(informacion) );
    };


    
    const onError = async (error) => {
    // callback llamado para todos los casos de error de Brick
    console.log(error);
    };
    
    
    const onReady = async () => {
    /*
        Callback llamado cuando Brick está listo.
        Aquí puedes ocultar cargamentos de su sitio, por ejemplo.
    */
    };

    const borrarError = (e) => {
        e.preventDefault();
        console.log('hola')
        dispatch( estadoErrorInitialAction() );
    }

    useEffect(() => {
        setSuscripcionElegida('');
        setMostrarOtrasSuscripciones(false);
        const newList = JSON.parse(JSON.stringify(suscripciones));
        const arr = newList.sort((a,b) => a.monto - b.monto );
        if(suscripcionActiva) {
            const id_suscripcion = suscripcionActiva.id_suscripcion;
            for(let i = 0; i < arr.length; i++){
                if(arr[i].id_suscripcion === id_suscripcion) {
                    arr[i].suscripto = true;
                } else {
                    arr[i].suscripto = false;
                }
            }
        }
        setInfoSuscripciones(arr);
    }, [suscriptor])

    return (
        <div>
            <FlexLanding justify="center" direction="column">  
                {!estado_suscripcion[0].prueba_gratis ? 
                    <FlexLanding justify="center">
                        <BotonPacienteDos style={{width: "auto"}}
                            onClick={(e) => probarGratis(e)}
                            disabled={disable}
                        >
                            Pruebe 15 días Gratis
                        </BotonPacienteDos>
                    </FlexLanding> 
                
                : 
                !suscripcionActiva && !suscripcionElegida ?
                    <FlexLanding justify="center"
                    tb_wrap="wrap"
                    min_wrap="wrap"
                    > 
                        {infoSuscripciones?.filter((item) => item.nombre_medio === 'mercado_pago').map((item, i) => (
                            <FlexLanding border={`3px solid ${colores[i].color}`} borderradius="15px" direction="column" cursor="pointer" bgcolor={item.id === suscripcionElegida ? '#23AAAF' : null}
                            xlg_margin="0rem 1rem 0 1rem" xlg_padding="1.5rem"
                            lg_margin="0rem 1rem 0 1rem" lg_padding="1.5rem"
                            dkt_margin="0rem 1rem 0 1rem" dkt_padding="1.5rem"
                            tb_margin="1rem 1rem 0 1rem" tb_padding="1.5rem"
                            min_margin="1rem 1rem 0 1rem" min_padding="1.5rem"
                                key={item.id}
                                onClick={() => (setSuscripcionElegida(item.id), setMontoElegido(item.monto))}
                            >
                                <TextoCorto fontweight="bold">
                                    {item.cantidad_suscriptos} pacientes
                                </TextoCorto>
                                <TextoCorto
                                xlg_margin="0.5rem 0 0rem 0rem" xlg_fontsize="0.7rem"
                                lg_margin="0.5rem 0 0rem 0rem" lg_fontsize="0.7rem"
                                dkt_margin="0.5rem 0 0rem 0rem" dkt_fontsize="0.7rem"
                                tb_margin="0.5rem 0 0rem 0rem" tb_fontsize="0.7rem"
                                min_margin="0.5rem 0 0rem 0rem" min_fontsize="0.7rem"
                                >
                                    $ {item.monto} ARS<br/>mensuales
                                </TextoCorto>
                            </FlexLanding>
                        ))}
                    </FlexLanding>
                :
                suscripcionElegida ?
                    <FlexLanding>
                        <FlexLanding align="center" justify="center" direction="column"
                        xlg_margin="1rem 0 0rem 0rem"
                        lg_margin="1rem 0 0rem 0rem"
                        dkt_margin="1rem 0 0rem 0rem"
                        tb_margin="1rem 0 0rem 0rem"
                        min_margin="1rem 0 0rem 0rem"
                        onClick={error ? (e) => borrarError(e) : null}
                        >   
                            {error ? <Error> {error.msg} </Error>: null }
                            <CardPayment
                            initialization={initialization}
                            customization={customization}
                            onSubmit={onSubmit}
                            onReady={onReady}
                            onError={onError}
                            />
                            <P>La activación puede tardar unos minutos a 1 hora, por favor cierre sesión y vuelva a loguearse</P>
                        </FlexLanding>
                        <FlexLanding align="center" justify="center" direction="column"
                        xlg_margin="1rem 0 0rem 0rem"
                        lg_margin="1rem 0 0rem 0rem"
                        dkt_margin="1rem 0 0rem 0rem"
                        tb_margin="1rem 0 0rem 0rem"
                        min_margin="1rem 0 0rem 0rem"
                        >
                            {infoSuscripciones?.filter((item) => item.nombre_medio === 'mercado_pago' && item.id === suscripcionElegida).map((item, i) => (
                                <FlexLanding border={`3px solid ${colores[i].color}`} borderradius="15px" direction="column" cursor="pointer" bgcolor={item.id === suscripcionElegida ? '#23AAAF' : null}
                                xlg_margin="0rem 1rem 0 1rem" xlg_padding="1.5rem"
                                lg_margin="0rem 1rem 0 1rem" lg_padding="1.5rem"
                                dkt_margin="0rem 1rem 0 1rem" dkt_padding="1.5rem"
                                tb_margin="1rem 1rem 0 1rem" tb_padding="1.5rem"
                                min_margin="1rem 1rem 0 1rem" min_padding="1.5rem"
                                    key={item.id}
                                >
                                    <TextoCorto fontweight="bold">
                                        {item.cantidad_suscriptos} pacientes
                                    </TextoCorto>
                                    <TextoCorto
                                    xlg_margin="0.5rem 0 0rem 0rem" xlg_fontsize="0.7rem"
                                    lg_margin="0.5rem 0 0rem 0rem" lg_fontsize="0.7rem"
                                    dkt_margin="0.5rem 0 0rem 0rem" dkt_fontsize="0.7rem"
                                    tb_margin="0.5rem 0 0rem 0rem" tb_fontsize="0.7rem"
                                    min_margin="0.5rem 0 0rem 0rem" min_fontsize="0.7rem"
                                    >
                                        $ {item.monto} ARS<br/>mensuales
                                    </TextoCorto>
                                </FlexLanding>
                            ))}
                            <BotonPacienteDos style={{width: "auto", backgroundColor: "#E7973C", color: "#FFFFFF", fontWeight: "bold"}}
                                    onClick={() => setSuscripcionElegida('')}
                                >
                                    Volver
                            </BotonPacienteDos>
                        </FlexLanding>
                    </FlexLanding>    
                :
                suscripcionActiva && !mostrarOtrasSuscripciones ? 
                <FlexLanding direction="column" justify="center" align="center">
                    <FlexLanding justify="center" align="center">
                        <FlexLanding justify="center" style={{textAlign: "left"}} wrap="wrap" width="50%">
                            {rol === '489431' ?
                                <P color="#514BBE" fontweight="bold">Hola {profesional}, actualmente tenes activa la siguiente suscripción.<br />Si deseas cambiarla apretá el botón de modificar suscripción.<br />Si deseas cancelarla apretá el botón de cancelar suscripción y volvé a activarla cuando lo requieras :)
                                </P>
                            :
                                <P color="#514BBE" fontweight="bold">Hola {profesional}, si el pago fue exitoso, por favor espera unos minutos y volvé a loguearte nuevamente.<br />Sabrás que ya esta listo cuando no aparezca más "NO TIENE ACTIVO EL MÉTODO"
                                </P>
                            }
                        </FlexLanding>
                        <FlexLanding justify="center">
                            {infoSuscripciones?.filter((item) => item.nombre_medio === 'mercado_pago' && item.suscripto ).map((item, i) => (
                                <FlexLanding border={`3px solid ${colores[i].color}`} borderradius="15px" direction="column"
                                xlg_margin="0rem 1rem 0 1rem" xlg_padding="1.5rem"
                                lg_margin="0rem 1rem 0 1rem" lg_padding="1.5rem"
                                dkt_margin="0rem 1rem 0 1rem" dkt_padding="1.5rem"
                                tb_margin="1rem 1rem 0 1rem" tb_padding="1.5rem"
                                min_margin="1rem 1rem 0 1rem" min_padding="1.5rem"
                                    key={item.id}
                                >
                                    <TextoCorto fontweight="bold">
                                        {item.cantidad_suscriptos} pacientes
                                    </TextoCorto>
                                    <TextoCorto
                                    xlg_margin="0.5rem 0 0rem 0rem" xlg_fontsize="0.7rem"
                                    lg_margin="0.5rem 0 0rem 0rem" lg_fontsize="0.7rem"
                                    dkt_margin="0.5rem 0 0rem 0rem" dkt_fontsize="0.7rem"
                                    tb_margin="0.5rem 0 0rem 0rem" tb_fontsize="0.7rem"
                                    min_margin="0.5rem 0 0rem 0rem" min_fontsize="0.7rem"
                                    >
                                        $ {item.monto} ARS<br/>mensuales
                                    </TextoCorto>
                                </FlexLanding>
                            ))}
                        </FlexLanding>
                    </FlexLanding>
                    <FlexLanding justify="center" margin="2rem 0 0 0">
                        
                        {suscriptor.activo ?
                            <BotonPacienteDos style={{width: "auto", backgroundColor: "#E7973C", color: "#FFFFFF", fontWeight: "bold"}}
                                onClick={(e) => pausarSuscripcion(e)}
                            >
                                Pausar Suscripción
                            </BotonPacienteDos>
                        :
                            null
                        }
                    </FlexLanding>
                    <Flex direccion="column" margin="2rem 0 0 0">
                        {mensaje ? <Flex>{mensaje}</Flex> : null}
                        <BotonPacienteDos style={{width: "auto", backgroundColor: "#E7973C", color: "#FFFFFF", fontWeight: "bold"}}
                            disabled={disable}
                            onClick={() => setMostrarOtrasSuscripciones(true)}
                        >
                            Cambiar suscripción
                        </BotonPacienteDos>
                    </Flex>
                    
                </FlexLanding>
                : mostrarOtrasSuscripciones ?
                    <FlexLanding justify="center"
                    tb_wrap="wrap"
                    min_wrap="wrap"
                    > 
                        {infoSuscripciones?.filter((item) => item.nombre_medio === 'mercado_pago' ).map((item, i) => (
                            <FlexLanding border={`3px solid ${colores[i].color}`} borderradius="15px" direction="column" height="100%" cursor="pointer" bgcolor={suscripcionElegida && item.id === suscripcionElegida ? '#23AAAF' : item.suscripto ? '#EFC340' : null}
                            xlg_margin="0rem 0.5rem 0 0.5rem" xlg_padding="1.2rem 0.5rem"
                            lg_margin="0rem 0.5rem 0 0.5rem" lg_padding="1.2rem 0.5rem"
                            dkt_margin="0rem 0.5rem 0 0.5rem" dkt_padding="1.2rem 0.5rem"
                            tb_margin="1rem 0.5rem 0 0.5rem" tb_padding="1.2rem 0.5rem"
                            min_margin="1rem 0.5rem 0 0.5rem" min_padding="1.2rem 0.5rem"
                                key={item.id}
                                onClick={() => (setSuscripcionElegida(item.id), setMontoElegido(item.monto))}
                            >
                                <TextoCorto fontweight="bold">
                                    {item.cantidad_suscriptos} pacientes
                                </TextoCorto>
                                <TextoCorto
                                xlg_margin="0.5rem 0 0rem 0rem" xlg_fontsize="0.7rem"
                                lg_margin="0.5rem 0 0rem 0rem" lg_fontsize="0.7rem"
                                dkt_margin="0.5rem 0 0rem 0rem" dkt_fontsize="0.7rem"
                                tb_margin="0.5rem 0 0rem 0rem" tb_fontsize="0.7rem"
                                min_margin="0.5rem 0 0rem 0rem" min_fontsize="0.7rem"
                                >
                                    $ {item.monto} ARS<br/>mensuales
                                </TextoCorto>
                            </FlexLanding>
                        ))}
                    </FlexLanding>
                :  estado_suscripcion[0].prueba_gratis && estado_suscripcion[0].cantidad_suscriptos === 0 && rol === '489431' ?
                        <FlexLanding direction="column" justify="center" align="center">
                            <FlexLanding justify="center" margin="0 0 1rem 0"
                            tb_wrap="wrap"
                            min_wrap="wrap"
                            > 
                                <P>Usted tiene activado la versión Demo. Una vez terminada esta podrá suscribirse o puede apretar el siguiente botón, cancelarla y suscribirse con todas sus funciones.</P>
                            </FlexLanding>
                            <BotonPacienteDos style={{width: "40%", backgroundColor: "#E7973C", color: "#FFFFFF", fontWeight: "bold"}}
                                onClick={(e) => cancelarVersionDemo(e)}
                                disabled={disable}
                            >
                                Cancelar versión Demo
                            </BotonPacienteDos>
                        </FlexLanding>
                : null
                }
            </FlexLanding>
        </div>
    );
}
 
export default MiSuscripcionAux;