import React, {Fragment, useState} from 'react';
import Modal from 'react-modal';
import { BotonLanding, Cerrar, FlexLanding, Imagen, TextoCorto } from '../Css/StyledLanding';

Modal.setAppElement('#root');

const ModalCostos = () => {

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const costos = [
        {id:1, pacientes:"3", costo:"11.500", color:"#4AA4A5", dolar:"10"},
        {id:2, pacientes:"5", costo:"19.200", color:"#E8BA35", dolar:"12"},
        {id:3, pacientes:"10", costo:"38.300", color:"#D53A77", dolar:"16"},
        {id:4, pacientes:"15", costo:"57.000", color:"#F6DEA9", dolar:"20"},
        {id:5, pacientes:"20", costo:"76.600", color:"#6261D4", dolar:"24"}
    ];

    const irLink = (e) => {
        e.preventDefault();
        window.open("http://metodoyogen.com/nueva-cuenta");
    }

    return ( 
        <Fragment>
            <div onClick={() => setModalIsOpen(true)} >COSTOS DE SUSCRIPCIÓN</div>
            <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
                <Cerrar
                    onClick={() => setModalIsOpen(false)}
                >
                    X
                </Cerrar>
                <FlexLanding justify="center">
                    <TextoCorto color="#E8BA35" fontweight="bold"
                    xlg_margin="2rem 0 4rem 0" xlg_fontsize="3rem"
                    lg_margin="2rem 0 4rem 0" lg_fontsize="3rem"
                    dkt_margin="2rem 0 4rem 0" dkt_fontsize="2rem"
                    tb_margin="2rem 0 4rem 0" tb_fontsize="2rem"
                    min_margin="2rem 0 4rem 0" min_fontsize="2rem"
                    >
                        Suscripción mensual
                    </TextoCorto>
                </FlexLanding>
                <FlexLanding justify="center"
                tb_wrap="wrap"
                min_wrap="wrap"
                >
                    {costos.map(item => (
                        <FlexLanding border={`3px solid ${item.color}`} borderradius="15px" direction="column"
                        xlg_margin="0rem 1rem 0 1rem" xlg_padding="1.5rem"
                        lg_margin="0rem 1rem 0 1rem" lg_padding="1.5rem"
                        dkt_margin="0rem 1rem 0 1rem" dkt_padding="1.5rem"
                        tb_margin="1rem 1rem 0 1rem" tb_padding="1.5rem"
                        min_margin="1rem 1rem 0 1rem" min_padding="1.5rem"
                            key={item.id}
                        >
                            <TextoCorto fontweight="bold">
                                {item.pacientes} pacientes
                            </TextoCorto>
                            <TextoCorto
                            xlg_margin="1rem 0 0rem 0rem" xlg_fontsize="0.7rem"
                            lg_margin="1rem 0 0rem 0rem" lg_fontsize="0.7rem"
                            dkt_margin="1rem 0 0rem 0rem" dkt_fontsize="0.7rem"
                            tb_margin="1rem 0 0rem 0rem" tb_fontsize="0.7rem"
                            min_margin="1rem 0 0rem 0rem" min_fontsize="0.7rem"
                            >
                                $ {item.costo} ARS<br/>mensuales
                            </TextoCorto>
                            <TextoCorto
                            xlg_margin="1.2rem 0 0rem 0rem" xlg_fontsize="0.7rem"
                            lg_margin="1.2rem 0 0rem 0rem" lg_fontsize="0.7rem"
                            dkt_margin="1.2rem 0 0rem 0rem" dkt_fontsize="0.7rem"
                            tb_margin="1.2rem 0 0rem 0rem" tb_fontsize="0.7rem"
                            min_margin="1.2rem 0 0rem 0rem" min_fontsize="0.7rem"
                            >
                                extranjeros<br/>$ {item.dolar} USD
                            </TextoCorto>
                        </FlexLanding>
                    ))}
                </FlexLanding>
                <FlexLanding justify="center" align="center"
                xlg_margin="3rem 0 0rem 0rem"
                lg_margin="3rem 0 0rem 0rem"
                dkt_margin="3rem 0 0rem 0rem"
                tb_margin="3rem 0 0rem 0rem"
                min_margin="3rem 0 0rem 0rem"
                >
                    <Imagen src={"/img/landing_modal_compu.png"} 
                        xlg_width="27%" xlg_height="27%"
                        lg_width="31%" lg_height="31%"
                        dkt_width="35%" dkt_height="35%"
                        tb_width="35%" tb_height="35%"
                        min_width="35%" min_height="35%"
                        />
                    <FlexLanding justify="center" align="center"
                    >
                        <BotonLanding bgcolor="#23AAAF" fontweight="bold"
                        xlg_margin="0rem 0.5rem 0rem 0rem" xlg_padding="1.2rem 3.2rem"
                        lg_margin="0rem 0.5rem 0rem 0rem" lg_padding="1.2rem 3.2rem"
                        dkt_margin="0rem 0.5rem 0rem 0rem" dkt_padding="1.2rem 3.2rem"
                        tb_margin="0rem 0.5rem 0rem 0rem" tb_padding="1.2rem 3.2rem"
                        min_margin="0rem 0.5rem 0rem 0rem" min_padding="1.2rem 3.2rem"
                            onClick={(e) => irLink(e)}
                        >
                            SUSCRIBITE
                        </BotonLanding>
                    </FlexLanding>
                </FlexLanding>
                <FlexLanding justify="center" align="center" style={{fontSize: "0.75rem"}}
                xlg_margin="0.5rem 0 0rem 0rem"
                lg_margin="0.5rem 0 0rem 0rem"
                dkt_margin="0.5rem 0 0rem 0rem"
                tb_margin="0.5rem 0 0rem 0rem"
                min_margin="0.5rem 0 0rem 0rem"
                >
                    Si sos de otro país envianos un mensaje a través de las redes para link de pago.<br/>
                    Precios a Octubre 2024
                </FlexLanding>
            </Modal>
            
        </Fragment>
    );
}
 
export default ModalCostos;